import Prestashop from 'prestashop';
import $ from 'jquery';
import 'slick-carousel';
import 'bootstrap-touchspin';
import 'cookieconsent';
import 'imports-loader?$=jquery!jfilestyle/src/jquery-filestyle';
import './cart';
import './checkout';

export default class Application {
    /**
     * [Ikanzen constructor]
     */
    constructor (name) {
        // Store name
        this.name = name;

        console.info('Application name:' + this.name);

        // Initialize Ikanzen Application
        this.initialize();
    }

    initialize () {
        let self = this;

        /**
         * Function
         */
        self.toggleNavbar();
        self.modalShow();
        self.initSlick();
        self.blockCartShowModal();
        self.showInputPassword();
        self.initCookieConsent();
        self.smoothScroll();

        /**
         * Touch event on mobile device
         * [Pour que les :hover en css soit active]
         */
        $('body').on('touchstart', () => {});

        /**
         * ajout balise small reasurrance
         */
        let blockReassurance = $('#block-reassurance li:last-child .h6 a');

        blockReassurance.html(blockReassurance.text().replace(/(\w+\s+\w+?)$/, '<small>$1</small>*'));

        /**
         * Show mobile menu
         */
        let mobileMenu = $('.mobile--menu');
        let mobileNav = $('.mobile--nav');

        mobileMenu.on('click', () => {
            mobileNav.fadeToggle();
        });

        /**
         * Toggle dropdown
         */
        let dropdown = $('.dropdown-form');

        dropdown.find('.btn-unstyle').click(() => {
            dropdown.toggleClass('open');
            $(this).attr('aria-expanded', 'true');
        });

        /**
         * Toggle product  detail image
         */
        let productWrapper = $('.js-product-images');

        self.toggleActiveImage(productWrapper);

        /**
         * Input spinner
         */
        let spinner = $('#quantity_wanted');

        self.createTouchspin(spinner);
        spinner.on('change', (_el) => {
            let _productRefresh = $('.product-refresh');

            $(_el.currentTarget).trigger('touchspin.stopspin');
            _productRefresh.trigger('click', { eventType: 'updatedProductQuantity' });
            _el.preventDefault();

            return false;
        });

        /**
         * Tunnel de commande
         */
        let checkout = $('#checkout-personal-information-step');
        let navlink = $('.nav-inline .nav-link', checkout);
        let tabPane = $('.tab-content .tab-pane', checkout);

        navlink.on('click', (_el) => {
            let _itemToggle = $(_el.currentTarget).attr('href');

            tabPane.removeClass('active');
            $(_itemToggle).addClass('active');
        });

        /**
         * Toggle size seaarch bar
         */
        let searchForm = $('.header-nav .header-nav--search-widget form');

        $('.header-nav .header-nav--search-widget .btn-search').on('click', () => {
            searchForm.toggleClass('toggleSearch');
        });

        /**
         * Prestasho event handler
         */
        Prestashop.on('updateProduct', (_event) => {
            // Product quantity
            if (_event && _event.product_minimal_quantity) {
                const minimalProductQuantity = parseInt(_event.product_minimal_quantity, 10);
                let quantityInput = $('#quantity_wanted');

                // @see http://www.virtuosoft.eu/code/bootstrap-touchspin/ about Bootstrap TouchSpin
                quantityInput.trigger('touchspin.updatesettings', { min: minimalProductQuantity });
            }
        });

        Prestashop.on('updatedProduct', (_event) => {
            if (_event && _event.product_cover_thumbnails) {
                self.initSlick();
            }
        });
    }

    /**
     * Toggle navbar content
     */
    toggleNavbar () {
        let _toogleNav = $('[data-toggle= collapse]');

        _toogleNav.on('click', (_el) => {
            _el.preventDefault();
            let _toogleIcon = $(_el.currentTarget).find('.collapse-icons');
            let _toogleTarget = $(_el.currentTarget).data('target');

            _toogleIcon.toggleClass('navbar-collapse');
            $(_toogleTarget).toggleClass('collapse');
        });
    }

    /**
     * Modal event handlers
     */
    modalShow () {
        let _trigger = $('[data-toggle = modal]');
        let _target = $(_trigger.data('target'));
        let _close = _target.find('[data-dismiss = modal]');

        $(document).on('click', '[data-toggle = modal]', () => {
            _target.addClass('in');
        });

        _close.on('click', () => {
            _target.removeClass('in');
        });
    }

    /**
     * Initialize swiper
     */
    initSlick () {
        let _slick = $('#slick-carousel');

        _slick.slick({
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: true,
            prevArrow: '<i class="ikanzenicons ikan-back"></i>',
            nextArrow: '<i class="ikanzenicons ikan-next"></i>'
        });
    }

    /**
     * Toogle active image
     */
    toggleActiveImage (imgWrapper) {
        $(document).on('click', '.js-toggle-active .js-thumb:not(.selected)', (_el) => {
            let _imgSrc = $('.js-toggle-active');
            let _imgToggle = $('.js-toggle-image');
            let _toggleSrc = imgWrapper.data('toggle-img');
            let _dataSrc = $(_el.currentTarget).data('image-large');

            _imgToggle.attr('src', _dataSrc);
            _imgSrc.find('.selected').removeClass('selected');
            $(_el.currentTarget).addClass('selected');

            if (_toggleSrc === 'modal') {
                $('.product-cover-modal').attr('src', _dataSrc);
            }
        });
    }

    /**
     * Block cart modal
     */
    blockCartShowModal () {
        Prestashop.blockcart = Prestashop.blockcart || {};

        Prestashop.blockcart.showModal = (modal) => {
            let getBlockCartModal = () => {
                return $('#blockcart-modal');
            };
            let _blockCartModal = getBlockCartModal();

            if (_blockCartModal.length) {
                _blockCartModal.remove();
            }

            $('body').append(modal);

            _blockCartModal = getBlockCartModal();
            setTimeout(() => {
                _blockCartModal.addClass('in');
                $('body').css('overflow', 'hidden');
            }, 2000);

            let _close = $('[data-dismiss = modal]', _blockCartModal);

            _blockCartModal.on('click', () => {
                _blockCartModal.removeClass('in');
                $('body').css('overflow', 'auto');
            });

            _close.on('click', () => {
                _blockCartModal.removeClass('in');
                $('body').css('overflow', 'auto');
            });
        };
    }

    /**
     * Init bootstrap touchspin
     */
    createTouchspin (element) {
        element.TouchSpin({
            verticalbuttons: true,
            verticalupclass: 'ikanzenicons ikan-up',
            verticaldownclass: 'ikanzenicons ikan-collapse',
            buttondown_class: 'btn',
            buttonup_class: 'btn',
            min: parseInt(element.data('min'), 10),
            max: 1000
        });
    }

    /**
     * Show password
     */
    showInputPassword () {
        let _btn = $('[data-action="show-password"]');

        _btn.on('click', (_el) => {
            let _btnText = $.trim($(_el.currentTarget).text());
            let _ancestor = $(_el.currentTarget).parents('.js-parent-focus');
            let _input = $('.js-visible-password', _ancestor).get(0);

            if (_btnText === 'Montrer') {
                $(_el.currentTarget).html('Cacher');
            } else {
                $(_el.currentTarget).html('Montrer');
            }

            if (_input.type === 'password') {
                _input.type = 'text';
            } else {
                _input.type = 'password';
            }
        });
    }

    /**
     * Init cookie consent
     */
    initCookieConsent () {
        window.cookieconsent.initialise({
            'palette': {
                'popup': { 'background': '#cdbead' },
                'button': { 'background': '#2c4b46' }
            },
            'content': {
                'message': 'Notre site utilise des cookies pour améliorer l’expérience utilisateur. Accepter leur utilisation pour profiter pleinement de votre navigation.',
                'dismiss': "J'accepte",
                'link': 'En savoir plus',
                'href': '#',
                'target': '_blank',
                'close': '&#x274c;'
            }
        });
    }

    /**
     * Smooth scroll
     */
    smoothScroll () {
        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .on('click', (event) => {
                // Make sure this.hash has a value before overriding default behavior
                if (event.currentTarget.hash !== '') {
                    // Prevent default anchor click behavior
                    event.preventDefault();

                    // Store hash
                    let hash = event.currentTarget.hash;

                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({ scrollTop: $(hash).offset().top }, 500, () => { // Add hash (#) to URL when done scrolling (default click behavior)
                        window.location.href.split('#')[0];
                    });
                } // End if
            });
    }
}
