/**
 * Create app instance
 */
import Bowser from 'bowser';
import Application from './application';
import Prestashop from 'prestashop';
import EventEmitter from 'events';

/**
 * Get class attribute
 * @param {HTMLElement} Element
 * @return {String} Class attribute value
 */
function getClass (element) {
    return element.getAttribute && element.getAttribute('class') || '';
}

/**
 * Set class attributes
 * @param {HTMLElement} element
 * @param {String} className
 */
function setClass (element, className) {
    const currentClass = getClass(element);
    let newClassName = '';
    let currentClassArray = currentClass.split(' ');

    if (element.nodeType === 1 && currentClass.indexOf(className) === -1) {
        currentClassArray.push(className);
        newClassName = currentClassArray.join(' ');
        element.setAttribute('class', newClassName);
    }
}

/**
 * Initialize app
 */
function initialize () {
    let isUnsupportedBrowser = false;
    const body = document.body;
    const browserUnsupported = Bowser.isUnsupportedBrowser(
        {
            'msie': '11',
            'safari': '9',
            'firefox': '50',
            'chrome': '60',
            'opera': '47'
        }
    );

    console.info('Browser info', Bowser);
    console.info('Browser unsupported', browserUnsupported);
    console.info('Bowser mobile || tablet', Bowser.mobile, Bowser.tablet);

    /**
     * Check outdated browser
     */
    if (!Bowser.mobile && !Bowser.tablet) {
        if ((!Bowser.version || browserUnsupported) && body instanceof HTMLElement) {
            setClass(body, 'browser-outdated');
            isUnsupportedBrowser = true;
        }
    }

    /**
     * Check mobile browser
     */
    if (Bowser.mobile || Bowser.tablet) {
        if (Bowser.version && body instanceof HTMLElement) {
            setClass(body, 'mobile--app');
        }
    } else {
        if (Bowser.version && body instanceof HTMLElement) {
            setClass(body, 'desktop--app');
        }
    }

    /**
     * Attach app to global namespace
     */
    if (!isUnsupportedBrowser) {
        window.inkazen = new Application('Inkazen') || {};
    }
}

// "inherit" EventEmitter
for (let i in EventEmitter.prototype) {
    Prestashop[i] = EventEmitter.prototype[i];
}

try {
    initialize();
} catch (error) {
    console.error(error);
}
